.sidebar {
  min-height: 100vh;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f0f4f9;
}
.sidebar img {
  width: 20px;
}
.sidebar .menu {
  display: block;
  margin-left: 10px;
  cursor: pointer;
}
.sidebar .new-chat {
  margin-top: 50px;
  display: inline-flex;
  gap: 10px;
  padding: 10px 15px;
  background-color: #e6eaf1;
  border-radius: 50px;
  font-size: 14px;
  color: grey;
  cursor: pointer;
}
.sidebar .recent {
  display: flex;
  flex-direction: column;
}
.sidebar .recent-title {
  margin-top: 30px;
  margin-bottom: 20px;
}
.sidebar .recent-entry {
  display: flex;
  align-items: start;
  gap: 10px;
  padding: 10px;
  padding-right: 40px;
  border-radius: 50px;
  color: #282828;
  cursor: pointer;
}
.sidebar .recent-entry:hover {
  background-color: #e2e6eb;
}
.sidebar .bottom {
  display: flex;
  flex-direction: column;
}
.sidebar .bottom-item {
  padding-right: 10px;
  cursor: pointer;
}
