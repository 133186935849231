.main {
  flex: 1;
  min-height: 100vh;
  padding-bottom: 15vh;
  position: relative;
}
.main .nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 22px;
  padding: 22px;
  color: #585858;
}
.main .nav > img {
  width: 40px;
  border-radius: 50%;
}
.main-container {
  max-width: 900px;
  margin: auto;
}
.main .greet {
  margin: 50px 8px;
  font-size: 56px;
  color: #ddd;
  font-weight: 500;
  padding: 20px;
}

.main .greet span {
  background: -webkit-linear-gradient(16deg, #4b90ff, #ff5546);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.main .cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 15px;
  padding: 20px;
}
.main .card {
  height: 200px;
  padding: 15px;
  background-color: #f0f4f9;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  box-shadow: inset 0 0 3px#837f7f;
}
.card > img {
  width: 35px;
  padding: 5px;
  position: absolute;
  background-color: #fff;
  border-radius: 20px;
  bottom: 10px;
  right: 10px;
}
.card > p {
  color: #585858;
  font-size: 17px;
}
.main .card:hover {
  background-color: #dfe4ed;
}

.main-bottom {
  position: absolute;
  bottom: 3;
  width: 100%;
  max-width: 900px;
  padding: 0px 20px;
  margin: auto;
}

.search-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  background-color: #f0f4f9;
  padding: 10px 20px;
  border-radius: 50px;
}

.search-box img {
  width: 24px;
  cursor: pointer;
}
.search-box input {
  flex: 1;
  background: transparent;
  border: none;
  outline: none;
  padding: 8px;
  font-size: 18px;
}
.search-box > div {
  display: flex;
  align-items: center;
  gap: 15px;
}
.bottom-info {
  font-size: 13px;
  /* position: absolute;
  bottom: 0; */
  margin: 20px auto;
  text-align: center;
  font-weight: 300;
}
